import { graphql } from '../../__graphqlTypes';

export const USER_FEATURES_FRAGMENT = graphql(`
    fragment UserFeaturesFragment on UserFeatures {
        enableCropResizeAssetLibraryV2
        enableEmailSubjectMergeTags
        enableEmailTemplateDivider
        enableEmailTemplatePlaceholder
        enableEmailTemplateSpacer
        enableNewGalleryPage
        leftHandNavigation
        showOldEmailTemplatesOption
        socialMergeFieldsFrontend
        templateGalleryV2
    }
`);
