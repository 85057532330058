import { graphql } from '../../__graphqlTypes';

export const GET_ALL_BRANDS_IDS = graphql(`
    query GetAllBrandsIds {
        me {
            id
            agency {
                id
                brands {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`);
