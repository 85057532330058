import { AppBar, Box, Divider, IconButton, Stack, Toolbar } from '@deltasierra/react/components/core';
import { HelpCircle, Menu01 } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import NavigationSwitchViewType from '../NavigationSwitchViewType';
import { UseNavigationSwitchViewTypeReturns } from '../hooks';
import {
    LeftNavigationTopBarNotificationsContainer,
    LeftNavigationTopBarNotificationsContainerProps,
} from './LeftNavigationTopBarNotificationsContainer';
import {
    LeftNavigationTopBarProfileContainer,
    LeftNavigationTopBarProfileContainerProps,
} from './LeftNavigationTopBarProfileContainer';

export type LeftNavigationTopBarProps = Omit<LeftNavigationTopBarNotificationsContainerProps, 't'> &
    Omit<LeftNavigationTopBarProfileContainerProps, 't'> &
    UseNavigationSwitchViewTypeReturns & {
        contextPicker?: ReactNode;
        helpUrl: string;
        homeHref: string;
        imageLogoAlt?: string;
        isOpenDrawer: boolean;
        isManager: boolean;
        logoImageSrc: string;
        onToggleCollapseDrawer: () => void;
        t: Translations<'Navigation'>;
    };

// eslint-disable-next-line max-lines-per-function
export const LeftNavigationTopBar = ({
    contextPicker,
    currentViewType,
    helpUrl,
    homeHref,
    imageLogoAlt = 'Digital Stack',
    isLoadingNotifications,
    isManager,
    isOpenDrawer,
    isOpenSwitchViewTypeModal,
    logoImageSrc,
    notifications,
    notificationUrl,
    onChangeCurrentViewType,
    onChangeDontShowAgain,
    onClickDismissAllNotifications,
    onClickDismissNotification,
    onClickNavigationSwitchViewButton,
    onCloseModal,
    onToggleCollapseDrawer,
    profile,
    t,
    user,
}: LeftNavigationTopBarProps): JSX.Element => (
    <AppBar
        aria-label="main navigation"
        component="nav"
        position="fixed"
        sx={{
            backgroundColor: 'common.white',
            boxShadow: 'none',
            color: 'common.black',
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            paddingTop: 'env(safe-area-inset-top)',
            position: 'fixed',
            right: 0,
            top: 0,
            whiteSpace: 'nowrap',
            zIndex: theme => theme.zIndex.drawer + 1,
        }}
    >
        <Toolbar>
            <Stack
                direction="row"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        columnGap: '1rem',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        paddingLeft: '7px',
                    }}
                >
                    <IconButton
                        aria-label={isOpenDrawer ? t('Close navigation') : t('Open navigation')}
                        edge="start"
                        sx={{
                            color: 'common.black',
                        }}
                        onClick={onToggleCollapseDrawer}
                    >
                        <Menu01 style={{ height: '1.5rem', width: '1.5rem' }} />
                    </IconButton>
                    <Box component={Link} href={homeHref} sx={{ alignItems: 'center', display: 'flex' }}>
                        <Image
                            alt={imageLogoAlt}
                            height={0}
                            priority
                            src={logoImageSrc}
                            style={{
                                height: '1.5rem',
                                width: '9rem',
                            }}
                            width={0}
                        />
                    </Box>
                </Box>
                {isManager && (
                    <NavigationSwitchViewType
                        currentViewType={currentViewType}
                        isOpenSwitchViewTypeModal={isOpenSwitchViewTypeModal}
                        t={t}
                        onChangeCurrentViewType={onChangeCurrentViewType}
                        onChangeDontShowAgain={onChangeDontShowAgain}
                        onClickNavigationSwitchViewButton={onClickNavigationSwitchViewButton}
                        onCloseModal={onCloseModal}
                    />
                )}
                {contextPicker && (
                    <>
                        <Divider flexItem orientation="vertical" />
                        <Box
                            sx={{
                                '& button': {
                                    height: '100%',
                                    width: '100%',
                                },
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'stretch',
                                minWidth: '1rem',
                            }}
                        >
                            {contextPicker}
                        </Box>
                        <Divider flexItem orientation="vertical" />
                    </>
                )}
                <Box
                    sx={{
                        alignItems: 'center',
                        columnGap: '0.25rem',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '0.25rem',
                    }}
                >
                    <LeftNavigationTopBarNotificationsContainer
                        isLoadingNotifications={isLoadingNotifications}
                        notificationUrl={notificationUrl}
                        notifications={notifications}
                        t={t}
                        onClickDismissAllNotifications={onClickDismissAllNotifications}
                        onClickDismissNotification={onClickDismissNotification}
                    />
                    <IconButton
                        LinkComponent={Link}
                        aria-label={t('Help')}
                        href={helpUrl}
                        sx={{ color: 'primary.main' }}
                    >
                        <HelpCircle />
                    </IconButton>
                    <LeftNavigationTopBarProfileContainer profile={profile} t={t} user={user} />
                </Box>
            </Stack>
        </Toolbar>
    </AppBar>
);

LeftNavigationTopBar.displayName = 'LeftNavigationTopBar';
