import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Modal,
    Typography,
} from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { NavigationViewType } from './enums';
import { UseNavigationSwitchViewTypeReturns } from './hooks';

type NavigationSwitchViewTypeProps = UseNavigationSwitchViewTypeReturns & {
    t: Translations<'Navigation'>;
};

export default function NavigationSwitchViewType({
    currentViewType,
    isOpenSwitchViewTypeModal,
    onChangeCurrentViewType,
    onChangeDontShowAgain,
    onClickNavigationSwitchViewButton,
    onCloseModal,
    t,
}: NavigationSwitchViewTypeProps): JSX.Element {
    return (
        <>
            <Button
                size="skinny"
                sx={{ alignSelf: 'center', gap: '1rem', marginX: 2 }}
                variant="outlined"
                onClick={onClickNavigationSwitchViewButton}
            >
                {t(currentViewType !== NavigationViewType.brand ? 'Brand view' : 'Location view')}
            </Button>
            <Modal
                buttonCancelText={t('Cancel')}
                buttonsSx={{ justifyContent: 'flex-start' }}
                open={isOpenSwitchViewTypeModal}
                sx={{ padding: '1rem 1.25rem 1.25rem 1.25rem', width: '444px' }}
                onCancel={onCloseModal}
                onClose={onCloseModal}
            >
                <Typography variant="h3">{t('Switch view')}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        marginTop: '0.625rem !important',
                    }}
                >
                    {[
                        {
                            description: t('Brand-wide reports, communication and management'),
                            id: NavigationViewType.brand,
                            title: t('Brand view'),
                        },
                        {
                            description: t('Plan, schedule, report on one or more locations'),
                            id: NavigationViewType.location,
                            title: t('Location view'),
                        },
                    ].map(({ description, id, title }) => (
                        <Box
                            component={Button}
                            key={id}
                            sx={{
                                alignItems: 'flex-start',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '8px',
                                color: 'common.black',
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '12px 16px',
                            }}
                            onClick={() => {
                                onChangeCurrentViewType(id);
                                onCloseModal();
                            }}
                        >
                            <Typography variant="h3">{title}</Typography>
                            <Typography sx={{ textAlign: 'start' }} variant="body1">
                                {description}
                            </Typography>
                        </Box>
                    ))}
                    <FormControlLabel
                        control={<Checkbox onChange={onChangeDontShowAgain} />}
                        label={t('Don’t show this again')}
                        sx={{ maxHeight: '1.5rem' }}
                    />
                    <Divider />
                </Box>
            </Modal>
        </>
    );
}

NavigationSwitchViewType.displayName = 'NavigationSwitchViewType';
