import { Avatar, CustomIcon, IconButton, Menu, MenuItem, Typography } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import Link from 'next/link';
import { MouseEvent } from 'react';
import { Profile, User } from '../types';

export type LeftNavigationTopBarProfileProps = {
    anchorEl: HTMLElement | null;
    onClickClose: () => void;
    onClickOpen: (event: MouseEvent<HTMLElement>) => void;
    open: boolean;
    t: Translations<'Navigation'>;
    user: User;
    profile: Profile;
};

export const LeftNavigationTopBarProfile = ({
    anchorEl,
    onClickClose,
    onClickOpen,
    open,
    profile,
    user,
}: LeftNavigationTopBarProfileProps): JSX.Element => (
    <>
        <IconButton onClick={onClickOpen}>
            <Avatar
                src={user.profilePicture}
                sx={{
                    backgroundColor: 'primary.main',
                    height: '1.85rem',
                    width: '1.85rem',
                }}
            >
                <Typography variant="h6">{user.initials}</Typography>
            </Avatar>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={open}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        color: 'grey.700',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        marginTop: 1.5,
                        minWidth: '8rem',
                        overflow: 'visible',
                    },
                },
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            onClick={onClickClose}
            onClose={onClickClose}
        >
            {profile.map(item => (
                <MenuItem
                    component={Link}
                    href={item.url}
                    key={item.id}
                    sx={{ display: 'flex', gap: '1rem' }}
                >
                    <CustomIcon customIconName={item.icon} style={{ height: '20px', width: '20px' }} />
                    <Typography noWrap sx={{ fontSize: '0.875rem', lineHeight: 'normal' }} variant="h6" >
                        {item.title}
                    </Typography>
                </MenuItem>
            ))}
        </Menu>
    </>
);

LeftNavigationTopBarProfile.displayName = 'LeftNavigationTopBarProfile';
