/* eslint-disable max-lines-per-function */
import {
    Box,
    CustomIcon,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ToggleCollapseContainer,
    Toolbar,
    Typography,
} from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { Navigation } from '../types';
import { NavigationViewType } from '../enums';

export type LeftNavigationLeftDrawerProps = {
    currentViewType: NavigationViewType;
    isOpenDrawer: boolean;
    navigation: Navigation;
    onToggleCollapseDrawer: () => void;
    t: Translations<'Navigation'>;
};

export const MAX_NAVIGATION_WIDTH = '12rem';
export const MIN_NAVIGATION_WIDTH = '5rem';

export const LeftNavigationLeftDrawer = ({
    currentViewType,
    isOpenDrawer,
    navigation,
    onToggleCollapseDrawer,
    t,
}: LeftNavigationLeftDrawerProps): JSX.Element => {
    const shouldShowMoreOption = !isOpenDrawer && currentViewType === NavigationViewType.brand;
    return (
        <Drawer
            open={isOpenDrawer}
            sx={{
                '& .MuiDrawer-paper': {
                    borderWidth: 0,
                    overflowX: 'hidden',
                    width: isOpenDrawer ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH,
                },
                gap: '0.625rem',
                overflowX: 'hidden',
                width: isOpenDrawer ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH,
            }}
            variant="permanent"
        >
            <Toolbar />
            <Box sx={{ marginBottom: '1rem' }}>
                {navigation.map(
                    section =>
                        (isOpenDrawer || section.isMaintainedOnClose) && (
                            <Fragment key={section.id}>
                                {isOpenDrawer && section.hasDividerAbove && (
                                    <Divider sx={{ margin: '0.625rem 0 0.75rem 0' }} />
                                )}
                                <List
                                    sx={[
                                        {
                                            '& .MuiListItem-root': isOpenDrawer
                                                ? {}
                                                : {
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      justifyContent: 'center',
                                                      marginBottom: '0.5rem',
                                                  },
                                            '& .MuiListItemButton-root': {
                                                '&:hover': {
                                                    backgroundColor: 'primary.light',
                                                },
                                                borderRadius: '8px',
                                                ...isOpenDrawer
                                                    ? { marginX: '0.5rem', padding: '0.188rem 1rem' }
                                                    : {
                                                          display: 'flex',
                                                          flexDirection: 'column',
                                                          justifyContent: 'center',
                                                          margin: '0 0.125rem',
                                                          padding: '0.375rem 0',
                                                      },
                                            },
                                            '& .MuiListItemText-root': {
                                                margin: '0',
                                            },
                                        },
                                        isOpenDrawer && {
                                            '& .MuiListItemIcon-root': {
                                                marginLeft: '-2px',
                                                marginRight: '1.25rem',
                                            },
                                        },
                                        { padding: 0 },
                                    ]}
                                >
                                    <>
                                        {isOpenDrawer && section.headerTitle && (
                                            <ListItem sx={{ padding: '0 0 0.5rem 1.5rem' }}>
                                                <Typography sx={{ fontWeight: 600 }} variant="h5">
                                                    {section.headerTitle}
                                                </Typography>
                                            </ListItem>
                                        )}
                                        {section.items.map(item => {
                                            const showSubItems =
                                                isOpenDrawer && item.subItems && item.subItems.length > 0;
                                            return (
                                                <ListItem
                                                    disablePadding
                                                    key={item.id}
                                                    sx={[
                                                        item.isSelected
                                                            ? {
                                                                  '& .MuiListItemIcon-root, .MuiListItemText-root': {
                                                                      color: 'primary.main',
                                                                  },
                                                              }
                                                            : {},
                                                    ]}
                                                >
                                                    <ToggleCollapseContainer
                                                        isDisabled={!showSubItems}
                                                        isOpenDefault
                                                        title={
                                                            <ListItemButton
                                                                LinkComponent={Link}
                                                                color="primary"
                                                                disabled={item.isDisabled}
                                                                href={item.navigationUrl}
                                                                sx={[
                                                                    {
                                                                        minHeight: 36,
                                                                    },
                                                                ]}
                                                            >
                                                                <ListItemIcon
                                                                    sx={{
                                                                        minWidth: 0,
                                                                    }}
                                                                >
                                                                    <CustomIcon customIconName={item.icon} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={item.title}
                                                                    primaryTypographyProps={{
                                                                        fontSize: isOpenDrawer
                                                                            ? '0.813rem'
                                                                            : '0.625rem',
                                                                        letterSpacing: '0',
                                                                        lineHeight: 'normal',
                                                                        margin: '0',
                                                                        textAlign: isOpenDrawer ? undefined : 'center',
                                                                        variant: isOpenDrawer ? 'body1' : 'body2',
                                                                    }}
                                                                />
                                                            </ListItemButton>
                                                        }
                                                    >
                                                        {showSubItems && (
                                                            <List disablePadding>
                                                                {item.subItems?.map(subItem => (
                                                                    <ListItem
                                                                        disablePadding
                                                                        key={subItem.id}
                                                                        sx={[
                                                                            {
                                                                                '& .MuiListItemButton-root': {
                                                                                    padding:
                                                                                        '0.188rem 0 0.188rem 3.75rem',
                                                                                },
                                                                                padding: 0,
                                                                            },
                                                                            subItem.isSelected
                                                                                ? {
                                                                                      '& .MuiListItemText-root': {
                                                                                          color: 'primary.main',
                                                                                      },
                                                                                  }
                                                                                : {},
                                                                        ]}
                                                                    >
                                                                        <ListItemButton
                                                                            LinkComponent={Link}
                                                                            color="primary"
                                                                            disabled={subItem.isDisabled}
                                                                            href={subItem.navigationUrl}
                                                                            key={subItem.id}
                                                                        >
                                                                            <ListItemText
                                                                                primary={subItem.title}
                                                                                primaryTypographyProps={{
                                                                                    fontWeight: 500,
                                                                                    marginY: 0,
                                                                                    variant: 'caption',
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        )}
                                                    </ToggleCollapseContainer>
                                                </ListItem>
                                            );
                                        })}
                                        {shouldShowMoreOption && (
                                            <ListItem disablePadding sx={{ flexDirection: 'row' }}>
                                                <ListItemButton
                                                    color="primary"
                                                    sx={{ gap: '2px', minHeight: 36, padding: '6px 0' }}
                                                    onClick={onToggleCollapseDrawer}
                                                >
                                                    <ListItemIcon sx={{ minWidth: 0, transform: 'rotate(90deg)' }}>
                                                        <CustomIcon customIconName={'DotsVertical'} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t('More')}
                                                        primaryTypographyProps={{
                                                            fontSize: '0.625rem',
                                                            lineHeight: 'normal',
                                                            margin: '0',
                                                            textAlign: 'center',
                                                            variant: 'body2',
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )}
                                    </>
                                </List>
                            </Fragment>
                        ),
                )}
            </Box>
        </Drawer>
    );
};

LeftNavigationLeftDrawer.displayName = 'LeftNavigationLeftDrawer';
