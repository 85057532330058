'use client';

import { ReactNode, useState } from 'react';
import { NavigationState, NavigationStateContext } from '../utils/NavigationStateContext';
import { DispatchNavigationStateContext } from '../utils/DispatchNavigationStateContext';

export const NavigationStateContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [navigationState, setNavigationState] = useState<NavigationState>({});

    return (
        <NavigationStateContext.Provider value={navigationState}>
            <DispatchNavigationStateContext.Provider value={setNavigationState}>
                {children}
            </DispatchNavigationStateContext.Provider>
        </NavigationStateContext.Provider>
    );
};

NavigationStateContextProvider.displayName = 'NavigationStateContextProvider';
