import { getCookie, setCookie } from 'cookies-next';
import { useEffect, useCallback, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import { AppWebRouterType } from '@deltasierra/frontend/routing/app-web';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import { doesUrlMatchNavigationParts, NavigationItemUrlParts } from '@deltasierra/features/left-navigation';
import {
    GET_ALL_BRANDS_IDS,
    GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY,
    GET_LOCATION_CLIENT,
    GetAllBrandsIdsQuery,
    GetAllBrandsIdsQueryVariables,
    getFragmentData,
    GetIsBrandsDashboardEnabledQuery,
    GetLocationClientQuery,
    GetLocationClientQueryVariables,
    LOCATION_FEATURES_FRAGMENT,
} from '@deltasierra/frontend/graphql';
import { NavigationViewType } from '../enums';

const SKIP_SWITCH_VIEW_TYPE_MODAL = 'skipSwitchBrandLocationModal';

type UseNavigationSwitchViewTypeProps = {
    appWebRouter: AppWebRouterType;
    clientId: string | null;
    isManager: boolean;
    locationId: string | null;
};

export type UseNavigationSwitchViewTypeReturns = {
    currentViewType: NavigationViewType;
    isOpenSwitchViewTypeModal: boolean;
    onChangeCurrentViewType: (viewType: NavigationViewType) => void;
    onChangeDontShowAgain: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickNavigationSwitchViewButton: () => void;
    onCloseModal: () => void;
};

export function useNavigationSwitchViewType({
    appWebRouter,
    clientId,
    isManager,
    locationId,
}: UseNavigationSwitchViewTypeProps): UseNavigationSwitchViewTypeReturns & {
    userBrandsQuantity: number;
} {
    const router = useRouter();
    const currentRoutePathName = usePathname();
    const skipSwitchBrandLocationModalCookie = getCookie(SKIP_SWITCH_VIEW_TYPE_MODAL);
    const [currentViewType, setCurrentViewType] = useState<NavigationViewType>(NavigationViewType.location);
    const [isOpenSwitchViewTypeModal, setIsOpenSwitchViewTypeModal] = useState(false);
    const [shouldSkipSwitchViewModal, setShouldSkipSwitchViewModal] = useState(
        skipSwitchBrandLocationModalCookie === 'true',
    );

    const { data: configResponse } = useSuspenseQuery<GetIsBrandsDashboardEnabledQuery | null>(
        GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY,
    );

    const { data: userBrands } = useSuspenseQuery<GetAllBrandsIdsQuery | null, GetAllBrandsIdsQueryVariables>(
        GET_ALL_BRANDS_IDS,
        !isManager || !clientId ? skipToken : undefined,
    );

    const { data: locationClient } = useSuspenseQuery<GetLocationClientQuery, GetLocationClientQueryVariables>(
        GET_LOCATION_CLIENT,
        locationId
            ? {
                  variables: {
                      locationId,
                  },
              }
            : skipToken,
    );

    const userBrandsQuantity = userBrands?.me.agency.brands.edges.length ?? 0;

    const isBrandGroupsOnDashboardEnabled = locationClient?.location?.client.showGroupsOnDashboard ?? false;

    const onChangeCurrentViewType = useCallback(
        (nextNewType: NavigationViewType) => {
            if (nextNewType === NavigationViewType.brand && configResponse?.config.features.brandsDashboard.enabled) {
                if (userBrandsQuantity > 1) {
                    window.location.replace(appWebRouter.get('/brand', '/agency/brands'));
                } else {
                    window.location.replace(appWebRouter.get('/brand', `/client/${clientId}/engagement/overview`));
                }
            } else if (nextNewType === NavigationViewType.location) {
                const locationFeatures = getFragmentData(
                    LOCATION_FEATURES_FRAGMENT,
                    locationClient?.location?.features,
                );
                const isDashboardV2Enabled = locationFeatures?.dashboardV2;
                if (isDashboardV2Enabled) {
                    const params = new URLSearchParams({
                        [QueryParameter.LocationId]: locationId ?? '',
                    });
                    if (isBrandGroupsOnDashboardEnabled) {
                        router.push(`/dashboard/brands?${params}`);
                    } else {
                        router.push(`/dashboard/?${params}`);
                    }
                } else {
                    window.location.replace(appWebRouter.get('/'));
                }
            }
            setCurrentViewType(nextNewType);
        },
        [
            appWebRouter,
            clientId,
            configResponse,
            isBrandGroupsOnDashboardEnabled,
            locationClient,
            locationId,
            router,
            userBrandsQuantity,
        ],
    );

    const onClickNavigationSwitchViewButton = useCallback(() => {
        if (shouldSkipSwitchViewModal) {
            const nextNewType =
                currentViewType === NavigationViewType.brand ? NavigationViewType.location : NavigationViewType.brand;
            onChangeCurrentViewType(nextNewType);
        } else {
            setIsOpenSwitchViewTypeModal(true);
        }
    }, [currentViewType, onChangeCurrentViewType, shouldSkipSwitchViewModal]);

    const onCloseModal = useCallback(() => {
        setIsOpenSwitchViewTypeModal(false);
    }, []);

    const onChangeDontShowAgain = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setShouldSkipSwitchViewModal(event.target.checked);
        setCookie(SKIP_SWITCH_VIEW_TYPE_MODAL, event.target.checked);
    }, []);

    useEffect(() => {
        const brandViewRoutes: NavigationItemUrlParts[] = [
            { startsWith: '/brand/agency/brands' },
            {
                endsWith: '/engagement/overview',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/region-group-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/location-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/template-use',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/send',
                startsWith: '/brand/communicate',
            },
            {
                endsWith: '/send-report',
                startsWith: '/brand/communicate',
            },
            {
                endsWith: '/manage/locations/locations',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/regions-groups/regions-groups',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/users/users',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/connections/platforms',
                startsWith: '/brand/client/',
            },
            {
                startsWith: '/agency/clients',
            },
            {
                endsWith: '/manage/learn-menu',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/activities',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/template-categories',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/hashtags',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/fonts',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/colors',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/style-guide',
                startsWith: '/brand/client/',
            },
        ];
        const locationViewRoutes: NavigationItemUrlParts[] = [
            { startsWith: '/dashboard' },
            { startsWith: '/trythis' },
            { startsWith: '/planner' },
            { startsWith: '/scheduled' },
            { startsWith: '/builderTemplateGallery' },
            { startsWith: '/templates' },
            { startsWith: '/assets/view' },
            { endsWith: '/overview', startsWith: '/reports/location/' },
            { startsWith: '/locationDetails' },
        ];

        const matchesLocationViewRoute = locationViewRoutes.some(locationViewRoute =>
            doesUrlMatchNavigationParts(currentRoutePathName, locationViewRoute),
        );
        const matchesBrandViewRoute = brandViewRoutes.some(brandViewRoute =>
            doesUrlMatchNavigationParts(currentRoutePathName, brandViewRoute),
        );

        if (matchesLocationViewRoute) {
            setCurrentViewType(NavigationViewType.location);
        } else if (matchesBrandViewRoute) {
            setCurrentViewType(NavigationViewType.brand);
        }
    }, [currentRoutePathName]);

    return {
        currentViewType,
        isOpenSwitchViewTypeModal,
        onChangeCurrentViewType,
        onChangeDontShowAgain,
        onClickNavigationSwitchViewButton,
        onCloseModal,
        userBrandsQuantity,
    };
}
