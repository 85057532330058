import { graphql } from '../../__graphqlTypes';

export const GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY = graphql(`
    query GetIsBrandsDashboardEnabled {
        config {
            id
            features {
                brandsDashboard {
                    enabled
                }
            }
        }
    }
`);
